<template>
  <div>
    <div class="panel">
      <gitbook :url="'https://docs.cloakone.com'"></gitbook>
    </div>
  </div>
</template>

<script>
import Gitbook from "@/view/content/gitbook.vue";

export default {
  name: "Campaign",
  components: {
    Gitbook
  }
};
</script>

<style>
.panel {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
    0 4px 24px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  border-radius: 12px;
  overflow: hidden;
  max-height: 92vh !important;
  min-height: 92vh !important;
  background: white;
  padding: 2px 0px 0 15px;
}
</style>
